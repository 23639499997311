import jquery from 'jquery';
import Swiper from 'swiper/swiper-bundle.min';
import accounting from "accounting-js";
import "jquery-validation";
import Cleave from 'cleave.js'
import toastr from "toastr";


window.$ = window.jQuery = jquery;
window.Swiper = Swiper;
window.Cleave = Cleave;
window.toastr = toastr;
window.accounting = accounting;
require('../bootstrap');
require('cleave.js/dist/addons/cleave-phone.kh');

require('@fancyapps/fancybox/dist/jquery.fancybox.min');

//image gallery
$('[data-fancybox="gallery"]').fancybox({
    buttons: [
        "slideShow",
        "thumbs",
        "zoom",
        "fullScreen",
        "close"
    ],
    loop: false,
    protect: true
});






new Cleave('#fm-register .input-phone', {
    phone: true,
    phoneRegionCode: 'kh'
});

new Cleave('#fm-login .input-phone', {
    phone: true,
    phoneRegionCode: 'kh'
});


// menu

$('#menu-button').click(function(e) {
    $("#menu").addClass('open');
    $('.background').addClass('new-bk');
    $("body").addClass("new-body");
});

$('#i-close').click(function(e) {
    $("#menu").removeClass('open');
    $('.background').removeClass('new-bk');
    $("body").removeClass("new-body");
});
$('.background').click(function(e) {
    $("#menu").removeClass('open');
    $(this).removeClass('new-bk');
    $("body").removeClass("new-body");
});

$(document).ready(function (){
    $('[data-confirm]').each(function () {
        let me = $(this),
            me_data = me.data('confirm');

        me_data = me_data.split("|");
        me.fireModal({
            title: me_data[0],
            body: me_data[1],
            buttons: [
                {
                    text: me.data('confirm-text-yes') || 'Yes',
                    class: 'btn btn-danger btn-shadow',
                    handler: function () {
                        eval(me.data('confirm-yes'));
                    }
                },
                {
                    text: me.data('confirm-text-cancel') || 'Cancel',
                    class: 'btn btn-secondary',
                    handler: function (modal) {
                        $.destroyModal(modal);
                        eval(me.data('confirm-no'));
                    }
                }
            ]
        })
    });
    //Back to Top
    $(window).scroll(function() {
        if ($(window).scrollTop() > 300) {
            $('#back-to-top').addClass('show');
        } else {
            $('#back-to-top').removeClass('show');
        }
    });
    $('#back-to-top').on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({scrollTop:0}, '500');
    });
    // $('#banner').delay(5000).addClass('show');
    setTimeout(AddClass, 5000);
    $('#btn-close').click(function(){
        let Visited = sessionStorage.getItem('advertisement');
        if (!Visited) {
            let advertisement = {
                'show':true,
                'advertisement': $('.page-area').data('ads')
            };
            sessionStorage.setItem('advertisement',JSON.stringify(advertisement));
        }
        else {
            Visited = JSON.parse(Visited);
            if (Visited.show==true){
                let advertisement = {
                    'show':false,
                    'advertisement': $('.page-area').data('ads')
                };
                sessionStorage.setItem('advertisement',JSON.stringify(advertisement));
            }
        }
        $('#banner').removeClass('show')
    });
    function AddClass(){
        let Visited = sessionStorage.getItem('advertisement');
        if (!Visited) {
            let advertisement = {
                'show':true,
                'advertisement': $('.page-area').data('ads')
            };
            sessionStorage.setItem('advertisement',JSON.stringify(advertisement));
            $('#banner').addClass('show')
        }
        else {
            let dataAdvertisement = $('.page-area').data('ads');
            Visited = JSON.parse(Visited);
            let sessionAdvertisement = Visited.advertisement;
            if (Visited.show==true && dataAdvertisement.id===sessionAdvertisement.id){
                $('#banner').addClass('show')
            }
            else {
                if (dataAdvertisement.id!==sessionAdvertisement.id){
                    let advertisement = {
                        'show':true,
                        'advertisement': $('.page-area').data('ads')
                    };
                    sessionStorage.setItem('advertisement',JSON.stringify(advertisement));
                    $('#banner').addClass('show')
                    $('#banner').addClass('show')
                }
            }
        }
    }

    $('.menuBtn').click(function() {
        $(this).toggleClass('act');
        if($(this).hasClass('act')) {
            $('.mainMenu').addClass('act');
        }
        else {
            $('.mainMenu').removeClass('act');
        }
    });
    $('#nav-phone').click(function() {
        $('#menu').addClass('show');
    });
    $('.icon-close').click(function(){
        $('#menu').removeClass('show')
    });
});
